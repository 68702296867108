<template>
	<div>
		<transition name="fade">
			<svg
				v-show="loading"
				width="100"
				height="100"
				viewBox="0 0 38 38"
				xmlns="http://www.w3.org/2000/svg"
				stroke="#fff"
				class="timeline__loader">
				<g
					fill="none"
					fill-rule="evenodd"><g
						transform="translate(1 1)"
						stroke-width="2"><circle
							stroke-opacity=".5"
							cx="18"
							cy="18"
							r="18"/><path d="M36 18c0-9.94-8.06-18-18-18">
								<animateTransform
									attributeName="transform"
									type="rotate"
									from="0 18 18"
									to="360 18 18"
									dur="1s"
									repeatCount="indefinite"/></path></g></g>
			</svg>
		</transition>
		<transition name="fade">
			<div
				v-if="!loading"
				class="handbooks__grid">
				<router-link
					:to="getPath(handbook)"
					v-for="(handbook, i) in accessibleTiles"
					:key="i"
					class="handbooks">
					<h2 class="handbooks__title">{{ handbook.post_title }}</h2>
				</router-link>
			</div>
		</transition>
	</div>
</template>

<script>
import {mapState} from 'vuex';
export default {
	props: {
		layout: {
			type: Object,
			required: true,
		},

		slug: {
			type: String
		}
	},

	data() {
		return {
			loading: false
		}
	},

	computed: {
		...mapState({
			currentUser: 'user',
			'online': 'online',
		}),

		accessibleTiles() {
			return this.layout.tiles.filter(handbook => {
				if (handbook.store_access !== '' && handbook.store_access !== null && typeof handbook.store_access !== 'undefined') {
					if(this.currentUser.store_access === null || this.currentUser.store_access === '') {
						return false;
					}

					return this.currentUser.store_access.filter(access => handbook.store_access.includes(access)).length > 0;
				}
				return true;
			});
		}
	},

	methods: {
		getPath(handbook) {
			let path = this.$route.path;
			if(!path.startsWith('/')) {
				path = '/' + path
			}

			if(!path.endsWith('/')) {
				path = path + '/';
			}

			return path + handbook.post_name + '/';
		}
	}
};
</script>
