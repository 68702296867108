<template>
	<div class="profile container">
		<h3 class="form__heading form__heading--no-top">Your Profile</h3>
		
		<p class="form__instruction">Manage your personal details and your notification preferences below.</p>

		<nav class="profile__nav">
			<button @click="activeTab = 'details'" class="profile__nav__link" :class="{active: activeTab === 'details'}">My Details</button>
			<button @click="activeTab = 'notifications'" class="profile__nav__link" :class="{active: activeTab === 'notifications'}">Notifications</button>
			<span class="profile__nav__bubble"></span>
		</nav>
		<Transition name="fade"  mode="out-in" appear>
			<div v-if="activeTab === 'details'" appear>
				<div>
					<form
						class="profile__form form"
						@submit.prevent="saveProfile"
					>
						<p class="form__instruction">The following personal details will only ever be used to personalise your Stellar KX dashboard and receieve notifications, it will never be shared with any third parties.</p>
						<div class="form__group">
							<label
								class="form__label"
								for="first_name"
							>First name</label>
							<input
								v-model="user.first_name"
								:readonly="!online"
								type="text"
								class="form__control"
								placeholder=""
								name="first_name"
							>
							<app-error name="first_name"/>
						</div>

						<div class="form__group">
							<label
								class="form__label"
								for="last_name"
							>Last name</label>
							<input
								id="last_name"
								v-model="user.last_name"
								:readonly="!online"
								type="text"
								class="form__control"
								placeholder=""
								name="last_name"
							>
							<app-error name="last_name"/>
						</div>

						<div class="form__group">
							<label
								class="form__label"
								for="email"
							>Email address</label>
							<input
								id="email"
								v-model="user.email"
								:readonly="!online"
								type="email"
								class="form__control"
								placeholder=""
								name="email"
							>
							<app-error name="email"/>
							<label
								class="form__label"
								for="description"
							>Biographical Information</label>
							<textarea
							v-model="user.description"
							rows="4"
							class="form__control"
							placeholder="A little bit about yourself..."/>

							<label
								class="form__label"
								for="email"
							>Department</label>
							<input
								id="department"
								v-model="user.department"
								type="text"
								class="form__control"
								placeholder=""
								name="department"
							>
							<app-error name="department"/>
							<label
								class="form__label"
								for="email"
							>Role</label>
							<input
								id="role"
								v-model="user.public_role"
								type="text"
								class="form__control"
								placeholder=""
								name="role"
							>
							<app-error name="role"/>

						</div>
						<div class="form__group">
							<div class="profile__group">
								<img
									v-if="image"
									:src="genImagePreview(image)"
									class="profile__avatar avatar-badge"
									:class="user.rank.tier_name">
								<img
									v-else-if="user.profile_avatar"
									:src="user.profile_avatar"
									class="profile__avatar avatar-badge"
									:class="'avatar-badge--'+user.rank.tier_name">
								<label
									class="profile__newpic"
									for="image">Upload a New Profile Avatar
									<svg
										width="22"
										height="18"
										viewBox="0 0 22 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										style="position: relative; top: 2px;"
									>
										<path
											d="M1.25 12.75L6.409 7.591C6.61793 7.38206 6.86597 7.21633 7.13896 7.10325C7.41194 6.99018 7.70452 6.93198 8 6.93198C8.29548 6.93198 8.58806 6.99018 8.86104 7.10325C9.13403 7.21633 9.38207 7.38206 9.591 7.591L14.75 12.75M13.25 11.25L14.659 9.841C14.8679 9.63206 15.116 9.46633 15.389 9.35325C15.6619 9.24018 15.9545 9.18198 16.25 9.18198C16.5455 9.18198 16.8381 9.24018 17.111 9.35325C17.384 9.46633 17.6321 9.63206 17.841 9.841L20.75 12.75M2.75 16.5H19.25C19.6478 16.5 20.0294 16.342 20.3107 16.0607C20.592 15.7794 20.75 15.3978 20.75 15V3C20.75 2.60218 20.592 2.22064 20.3107 1.93934C20.0294 1.65804 19.6478 1.5 19.25 1.5H2.75C2.35218 1.5 1.97064 1.65804 1.68934 1.93934C1.40804 2.22064 1.25 2.60218 1.25 3V15C1.25 15.3978 1.40804 15.7794 1.68934 16.0607C1.97064 16.342 2.35218 16.5 2.75 16.5ZM13.25 5.25H13.258V5.258H13.25V5.25ZM13.625 5.25C13.625 5.34946 13.5855 5.44484 13.5152 5.51517C13.4448 5.58549 13.3495 5.625 13.25 5.625C13.1505 5.625 13.0552 5.58549 12.9848 5.51517C12.9145 5.44484 12.875 5.34946 12.875 5.25C12.875 5.15054 12.9145 5.05516 12.9848 4.98484C13.0552 4.91451 13.1505 4.875 13.25 4.875C13.3495 4.875 13.4448 4.91451 13.5152 4.98484C13.5855 5.05516 13.625 5.15054 13.625 5.25V5.25Z"
											stroke="currentcolor"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"/>
									</svg>
								</label>
								<input
									style="opacity:0; width:0;"
									id="image"
									type="file"
									multiple="true"
									name="image"
									@change="filesChange($event.target.name, $event.target.files);"
								>
								
							</div>
						</div>

						<p style="margin: 1rem 0; font-style: italic;">{{ user.rank_description }}</p>

						
						<button
							:disabled="isSaving || !online"
							class="button button--pill ripple form__save">
								<span v-if="isSaved">Saved!</span>
								<span v-else-if="isSaving">Saving...</span>
								<span v-else>Save Personal Details</span>
								<svg
								v-if="isSaving"
								style="margin-bottom: -4px;"
									width="16"
									height="16"
									viewBox="0 0 38 38"
									xmlns="http://www.w3.org/2000/svg"
									stroke="#fff"
								>
									<g
										fill="none"
										fill-rule="evenodd">
										<g
											transform="translate(1 1)"
											stroke-width="2">
											<circle
												stroke-opacity=".5"
												cx="18"
												cy="18"
												r="18"/>
											<path d="M36 18c0-9.94-8.06-18-18-18">
												<animateTransform
													attributeName="transform"
													type="rotate"
													from="0 18 18"
													to="360 18 18"
													dur="1s"
													repeatCount="indefinite"/>
											</path>
										</g>
									</g>
								</svg>
							</button>

						<app-error name="profile"/>
					</form>

					<hr class="profile__sep">

					<form class="profile__form form">
						<h3 class="form__heading">App Theme</h3>
						<p class="form__instruction">Toggle the StellarKXi application between light and dark mode.</p>

						<div class="form__group">
							<label
								class="form__inlinelabel"
								for="notify_events"
								style="margin-bottom: .5rem;"
							>Your current StellarKXi theme</label>
							<toggle-button
								:color="{checked: '#111111', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
								:sync="true"
								:height="24"
								:width="105"
								:font-size="12"
								:name="'theme'"
								v-model="currentTheme"
								:labels="{checked: 'Dark Mode', unchecked: 'Light Mode'}"
								tag="theme"
								style="margin-bottom: .5rem;"
								@change="toggleThemeMode"
							/>
						</div>
					</form>

					<hr class="profile__sep">


					<form
						class="profile__form form"
						@submit.prevent="changePassword"
					>

						<h3 class="form__heading">Update Password</h3>
						<p class="form__instruction">If you wish to change your password, please enter your current password, followed by your desired password, and then your new password again to confirm.</p>

						<div class="form__group">
							<label
								class="form__label"
								for="current_password"
							>Current password</label>
							<input
								id="current_password"
								v-model="password.current"
								:readonly="!online"
								type="password"
								class="form__control"
								placeholder="********"
								name="current_password"
							>
							<app-error name="current_password"/>
						</div>

						<div class="form__group">
							<label
								class="form__label"
								for="password"
							>New password</label>
							<input
								id="password"
								v-model="password.new"
								:readonly="!online"
								type="password"
								class="form__control"
								placeholder="********"
								name="password"
							>
							<app-error name="password"/>
						</div>

						<div class="form__group">
							<label
								class="form__label"
								for="password_confirmation"
							>Confirm password</label>
							<input
								id="password_confirmation"
								v-model="password.confirmation"
								:readonly="!online"
								type="password"
								class="form__control"
								placeholder="********"
								name="password_confirmation"
							>
							<app-error name="password__confirmation"/>
						</div>

						<button
							:disabled="isSaving || !online"
							class="button button--pill ripple form__save"
						>Change Password</button>
					</form>

					<hr class="profile__sep">

					<div class="profile__form">
						<h3 class="form__heading">Logout</h3>
						<p class="form__instruction">Completely logout of the StellarKXi application.</p>

						<button
							class="button button--pill ripple form__save"
							@click.prevent="logout"
						>Logout</button>
					</div>
				</div>
			</div>
			
			<div v-else-if="activeTab === 'notifications'" appear>
				<div class="profile__notifications">
					<p class="form__instruction">Here you can update your notification preferences, decide on what you would like to be notified about.</p>

					<form>

						<div class="form__group profile__notifications__toggle ">
							<label
								class="form__inlinelabel"
								for="notify_new_posts"
							>Notify me when anyone creates a new post</label>
							<toggle-button
								:color="{checked: '#0dac5b', unchecked: '#888', disabled: '#CCCCCC'}"
								:sync="true"
								:height="24"
								:width="55"
								:font-size="12"
								:name="'notify_new_posts'"
								v-model="user.notify_new_posts"
								:labels="{checked: 'Yes', unchecked: 'No'}"
								tag="notify_new_posts"
								@change="notificationPrefChanged"
							/>
						</div>

						<div class="form__group profile__notifications__toggle ">
							<label
								class="form__inlinelabel"
								for="notify_new_comment"
							>Notify me when someone comments on one of my posts</label>
							<toggle-button
								:color="{checked: '#0dac5b', unchecked: '#888', disabled: '#CCCCCC'}"
								:sync="true"
								:height="24"
								:width="55"
								:font-size="12"
								:name="'notify_new_comment'"
								v-model="user.notify_new_comment"
								:labels="{checked: 'Yes', unchecked: 'No'}"
								tag="notify_new_comment"
								@change="notificationPrefChanged"
							/>
						</div>

						<div class="form__group profile__notifications__toggle ">
							<label
								class="form__inlinelabel"
								for="notify_comment_reply"
							>Notify me when someone replies to one of my comments</label>
							<toggle-button
								:color="{checked: '#0dac5b', unchecked: '#888', disabled: '#CCCCCC'}"
								:sync="true"
								:height="24"
								:width="55"
								:font-size="12"
								:name="'notify_comment_reply'"
								v-model="user.notify_comment_reply"
								:labels="{checked: 'Yes', unchecked: 'No'}"
								tag="notify_comment_reply"
								@change="notificationPrefChanged"
							/>
						</div>

						<div class="form__group profile__notifications__toggle ">
							<label
								class="form__inlinelabel"
								for="notify_new_like"
							>Notify me when someone likes one of my posts</label>
							<toggle-button
								:color="{checked: '#0dac5b', unchecked: '#888', disabled: '#CCCCCC'}"
								:sync="true"
								:height="24"
								:width="55"
								:font-size="12"
								:name="'notify_new_like'"
								v-model="user.notify_new_like"
								:labels="{checked: 'Yes', unchecked: 'No'}"
								tag="notify_new_like"
								@change="notificationPrefChanged"
							/>
						</div>

						<div class="form__group profile__notifications__toggle ">
							<label
								class="form__inlinelabel"
								for="notify_mention"
							>Notify me when someone mentions me in a comment</label>
							<toggle-button
								:color="{checked: '#0dac5b', unchecked: '#888', disabled: '#CCCCCC'}"
								:sync="true"
								:height="24"
								:width="55"
								:font-size="12"
								:name="'notify_mention'"
								v-model="user.notify_mention"
								:labels="{checked: 'Yes', unchecked: 'No'}"
								tag="notify_mention"
								@change="notificationPrefChanged"
							/>
						</div>
					</form>
								




				</div>
			</div>
		</Transition>
	</div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import AuthService from '@/services/AuthService';

export default {
	data () {
		return {
			user: {},
			password: {},
			isSaving: false,
			isSaved: false,
			currentTheme: false,
			image: null,
			hasImage: false,
			formData: new FormData(),
			activeTab: 'details'
		};
	},

	computed: {
		...mapState({
			currentUser: 'user',
			'online': 'online',
		}),
	},

	watch: {
		currentUser () {
			if (this.currentUser) {
				this.user = {...this.currentUser};
			} else {
				this.user = {};
			}
		},

		online (status) {
			if (status) {
				this.loadUser();
			}
		},
	},

	mounted () {
		this.loadUser();

		this.currentTheme = ((window.localStorage.getItem('theme') === null || window.localStorage.getItem('theme') === 'dark-theme'));
	},

	methods: {
		...mapMutations([
			'captureErrors',
			'clearErrors',
		]),

		...mapActions(['loadUser']),

		saveProfile () {
			this.isSaving = true;
			this.clearErrors();
			this.user.theme = window.localStorage.getItem('theme');


			this.formData.append('theme', this.user.theme);
			this.formData.append('first_name', this.user.first_name);
			this.formData.append('last_name', this.user.last_name);
			this.formData.append('description', this.user.description);
			this.formData.append('email', this.user.email);
			this.formData.append('department', this.user.department);
			this.formData.append('public_role', this.user.public_role);

			window.axios.post('wp-json/kxi/v1/profile/update', this.formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}).then(res => {
				this.isSaving = false;
				this.isSaved = true;

				setTimeout(() => {
					this.isSaved = false;
				}, 1500);
			
				// this.user = res.data;
			}).catch(err => {
				this.isSaving = false;
				this.captureErrors(err);
			});
		},

		changePassword () {
			this.clearErrors();

			const data = {
				'current_password': this.password.current,
				'password': this.password.new,
				'password_confirmation': this.password.confirmation,
			};

			window.axios.post('wp-json/kxi/v1/password/update', data).then(res => {
				this.password = {};
			}).catch(err => {
				this.captureErrors(err);
			});
		},

		logout () {
			AuthService.clearToken();
			window.location.replace('/login');
		},

		notificationToggled (event) {
			let packet = {
				'notify_new_posts' : this.user.notify_new_posts ?? false,
				'notify_new_comment' : this.user.notify_new_comment ?? false,
				'notify_comment_reply' : this.user.notify_comment_reply ?? false,
				'notify_new_like' : this.user.notify_new_like ?? false,
				'notify_mention' : this.user.notify_mention ?? false,
			};
			window.axios.post('wp-json/kxi/v1/profile/notifications', packet)
		},

		notificationPrefChanged (event) {
			this.notificationToggled();
		},

		toggleThemeMode () {
			// Going from dark to light?
			if (window.localStorage.getItem('theme') === null || window.localStorage.getItem('theme') === 'dark-theme') {
				window.localStorage.setItem('theme', 'light-theme');

				document.body.classList.add('light-theme');
				document.documentElement.classList.add('light-theme');
				return;
			}

			// Light to dark
			window.localStorage.setItem('theme', 'dark-theme');
			document.documentElement.classList.remove('light-theme');
			document.body.classList.remove('light-theme');
		},

		genImagePreview (file) {
			if (file) {
				return file ? URL.createObjectURL(file) : null;
			}

			return null;
		},

		filesChange (fieldName, fileList) {
			if (!fileList.length) {
				this.hasImage = false;
				return;
			}

			this.hasImage = true;
			this.image = fileList[0];

			this.formData.append('image', this.image, this.image.name);
		},
	},
};
</script>
