import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/components/LoginComponent';
import Home from '@/components/HomeComponent';
import Maintenance from '@/components/MaintenanceComponent';
import Insights from '@/components/InsightsComponent';
import Profile from '@/components/ProfileComponent';
import PublicProfile from '@/components/PublicProfileComponent';
import NewsArticle from '@/components/NewsArticleComponent';
import LeaveRequest from '@/components/LeaveRequestComponent';
import PageContent from '@/components/PageContentComponent';
import PageNotFound from '@/components/PageNotFoundComponent';
import KXiError from '@/components/KXiErrorComponent';
import KXiForbidden from '@/components/KXiForbiddenComponent';
import AccountConfirm from '@/components/AccountConfirmComponent';
import Notifications from '@/components/NotificationsComponent';
import ForgotPassword from '@/components/ForgotPasswordComponent';
import ResetPassword from '@/components/ResetPasswordComponent';
import trainingProfile from '@/components/TrainingProfileComponent';
import SubmitPostComponent from '@/components/SubmitPostComponent';
import TrainingSessionsComponent from '@/components/TrainingSessionsComponents/TrainingSessionsComponent';
import TrainingSessionFormComponent from '@/components/TrainingSessionsComponents/TrainingSessionFormComponent';
import TrainingSessionAttendeeComponent from '@/components/TrainingSessionsComponents/TrainingSessionAttendeeComponent';
import SinglePost from '@/components/TimelineComponents/SinglePostComponent';

Vue.use(Router);

const maintenance = false;

let routes = [
		{
			path: '/',
			name: 'home',
			component: Home,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/posts/:id',
			name: 'post',
			component: SinglePost,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/login',
			name: 'login',
			component: Login,
		},

		{
			path: '/forgot-password',
			name: 'forgot-password',
			component: ForgotPassword,
		},

		{
			path: '/reset-password',
			name: 'reset-password',
			component: ResetPassword,
		},

		{
			path: '/notifications',
			name: 'notifications',
			component: Notifications,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/insights',
			name: 'insights',
			component: Insights,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/training-profile',
			name: 'training-profile',
			component: trainingProfile,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/news/:slug',
			name: 'article',
			component: NewsArticle,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/profile',
			name: 'profile',
			component: Profile,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/profile/:id',
			name: 'usersprofile',
			component: PublicProfile,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/leave-request',
			name: 'leave_request',
			component: LeaveRequest,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/account-confirm',
			name: 'account-confirm',
			component: AccountConfirm,
		},

		{
			path: '/error',
			name: 'error',
			component: KXiError,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/forbidden',
			name: 'forbidden',
			component: KXiForbidden,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/oops',
			name: 'oops',
			component: PageNotFound,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/submit-post',
			name: 'submit-post',
			component: SubmitPostComponent,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/training-sessions',
			name: 'training-sessions',
			component: TrainingSessionsComponent,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/training-sessions/new',
			name: 'new-training-sessions',
			component: TrainingSessionFormComponent,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/training-sessions/:id',
			name: 'new-training-sessions',
			component: TrainingSessionFormComponent,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: '/training-session/:id',
			name: 'training-session',
			component: TrainingSessionAttendeeComponent,
			meta: {
				requiresAuth: true,
			},
		},

		// {
		// 	path: '/handbooks/',
		// 	name: 'handbooks',
		// 	component: HandbooksComponent,
		// 	meta: {
		// 		requiresAuth: true,
		// 	},
		// },

		// {
		// 	path: '/handbooks/:slug',
		// 	name: 'handbook',
		// 	component: HandbookComponent,
		// 	meta: {
		// 		requiresAuth: true,
		// 	},
		// },

		// {
		// 	path: '/handbooks/:slug/:child',
		// 	name: 'handbook',
		// 	component: HandbookComponent,
		// 	meta: {
		// 		requiresAuth: true,
		// 	},
		// },

		{
			path: '*',
			name: 'content',
			component: PageContent,
			meta: {
				requiresAuth: true,
			},
		},
	];

if(maintenance) {
	routes = [{
		path: '*',
		name: 'maintenance',
		component: Maintenance,
		meta: {
			requiresAuth: false,
		},
	}];
}


const router = new Router({
	scrollBehavior () {
		return { x: 0, y: 0 };
	},
	mode: 'history',
	routes: routes,
	
});

if(!maintenance){
	router.beforeEach((to, from, next) => {
		if (to.meta.requiresAuth && !window.auth.isLoggedIn()) {
			next({name: 'login'});
		} else {
			next();
		}
	});
}

export default router;
