<template>
	<div id="app">
		<app-header
			v-if="isLoggedIn && !searchOpen"
		/>
		<router-view />
		<app-nav v-if="isLoggedIn" />
		<app-search :class="{show: searchOpen}" />
		<notification />
		<post-update :user="user"  v-if="isLoggedIn"/>
	</div>
</template>

<script>
import AppSearch from '@/components/SearchComponent';
import PostUpdate from '@/components/TimelineComponents/PostUpdateComponent';
import Notification from '@/components/Notification';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
	name: 'App',

	components: {
		AppSearch,
		Notification,
		PostUpdate,
	},

	data () {
		return {
			isLoggedIn: false,
			newPosts: [],
		};
	},

	computed: {
		...mapState([
			'searchOpen',
			'user',
			'tiers',
		]),
	},

	watch: {
		'$route' (to, from) {
			if (to.path !== from.path) {
				this.isLoggedIn = window.auth.isLoggedIn();
			}
		},
	},

	mounted () {

		this.loadUser();
		this.loadTiers();
		this.isLoggedIn = window.auth.isLoggedIn();
		this.listenToOnlineStatus();
		this.touchActivity();

		this.$root.$on('openSearch', () => {
			this.openSearch();
		});


		setInterval(() => {
			this.touchActivity()
		}, 60000);
	},

	methods: {
		...mapActions(['loadUser', 'loadTiers', 'touchActivity']),

		...mapMutations(['setOnline', 'openSearch']),


		listenToOnlineStatus () {
			window.addEventListener('online', () => {
				this.setOnline(true);
			});

			window.addEventListener('offline', () => {
				this.setOnline(false);
			});
		},
	},
};
</script>
