<template>
<div>
	<transition name="fade">
		<article
			v-if="ready"
			class="article container"
		>
			<section
				v-for="(layout, index) in page.acf.flexible_content"
				:key="index"
				:class="'layout layout--' + layout.acf_fc_layout + ' index--' + index"
			>
				<section-header
					v-if="layout.acf_fc_layout == 'section_header'"
					:layout="layout"
					:title="page.title"
					:index="index"
					:slug="get_current_slug()"
				/>

				<app-quick-links
					v-if="layout.acf_fc_layout == 'section_header'"
					:sub-title="page.title.rendered"
				/>

				<custom-menu
					v-if="layout.acf_fc_layout == 'menu'"
					:menu="layout.menu"
				/>

				<mixed-content
					v-if="layout.acf_fc_layout == 'mixed_content'"
					:layout="layout"
				/>

				<mixed-content-carousel
					v-if="layout.acf_fc_layout == 'mixed_content_carousel'"
					:layout="layout"
				/>

				<mixed-content-grid
					v-if="layout.acf_fc_layout == 'mixed_content_grid'"
					:layout="layout"
				/>

				<chat-to-mash-phone
					v-if="layout.acf_fc_layout == 'contact_phone'"
					:layout="layout"
				/>

				<chat-to-mash-mail
					v-if="layout.acf_fc_layout == 'contact_mail'"
					:layout="layout"
				/>

				<mixed-content-form
					v-if="layout.acf_fc_layout == 'mixed_content_form'"
					:form="layout.form"
				/>

				<full-width-image
					v-if="layout.acf_fc_layout == 'full_width_image'"
					:image="layout.image"
				/>

				<holiday-balance
					v-if="layout.acf_fc_layout == 'remaining_holiday'"
					:layout="layout"
				/>

				<pirkx-dashboard
					v-if="layout.acf_fc_layout == 'pirkx_dashboard_link'"
					:layout="layout"
				/>
				
				<google-form v-if="layout.acf_fc_layout =='google_form_embed'" :layout="layout"></google-form>
				
				<handbook v-if="layout.acf_fc_layout === 'handbook_tiles'" :layout="layout" :slug="get_current_slug()"></handbook>
			</section>
		</article>
	</transition>
	<transition name="fade">
			<svg
				v-show="!ready"
				width="100"
				height="100"
				viewBox="0 0 38 38"
				xmlns="http://www.w3.org/2000/svg"
				stroke="#fff"
				class="timeline__loader">
				<g
					fill="none"
					fill-rule="evenodd"><g
						transform="translate(1 1)"
						stroke-width="2"><circle
							stroke-opacity=".5"
							cx="18"
							cy="18"
							r="18"/><path d="M36 18c0-9.94-8.06-18-18-18">
								<animateTransform
									attributeName="transform"
									type="rotate"
									from="0 18 18"
									to="360 18 18"
									dur="1s"
									repeatCount="indefinite"/></path></g></g>
			</svg>
		</transition>
</div>
</template>

<script>
import SectionHeader from '@/components/ArticleComponents/SectionHeaderComponent';
import MixedContent from '@/components/ArticleComponents/MixedContentComponent';
import MixedContentCarousel from '@/components/ArticleComponents/MixedContentCarouselComponent';
import MixedContentGrid from '@/components/ArticleComponents/MixedContentGridComponent';
import MixedContentForm from '@/components/ArticleComponents/MixedContentFormComponent';
import CustomMenu from '@/components/ArticleComponents/CustomMenuComponent';
import ChatToMashPhone from '@/components/ChatToMashPhoneComponent';
import ChatToMashMail from '@/components/ChatToMashMailComponent';
import FullWidthImage from '@/components/ArticleComponents/FullWidthImageComponent';
import HolidayBalance from '@/components/ArticleComponents/HolidayBalanceComponent';
import PirkxDashboard from '@/components/ArticleComponents/PirkxDashboardComponent';
import Handbook from '@/components/ArticleComponents/HandbookTilesComponent';
import GoogleForm from '@/components/GoogleFormComponent';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'PageContent',

	components: {
		SectionHeader,
		MixedContent,
		MixedContentCarousel,
		MixedContentGrid,
		MixedContentForm,
		CustomMenu,
		ChatToMashPhone,
		ChatToMashMail,
		FullWidthImage,
		HolidayBalance,
		PirkxDashboard,
		GoogleForm,
		Handbook
	},

	data () {
		return {
			page: null,
			ready: false,
		};
	},

	computed: {
		...mapGetters(['postStatuses']),
	},

	watch: {
		'$route' (to, from) {
			if (to.path !== from.path) {
				this.ready = false;
				this.get_page();
				document.body.scrollTop = 0;
			}
		},
	},

	created () {
		this.loadUser();
		this.get_page();
	},

	methods: {
		...mapActions(['loadUser']),
		get_current_slug () {
			let routePathParts = this.$route.path.split('/').filter(part => part.length);

			if (routePathParts.slice(-1)[0] !== 'undefined') {
				return routePathParts.slice(-1)[0];
			}
			return this.$route.path;
		},

		get_page () {
			let slug = this.get_current_slug();
			let url = 'wp-json/wp/v2/pages?_embed&slug=' + slug + '&status=' + this.postStatuses;
			if (!isNaN(slug)) {
				url = 'wp-json/wp/v2/pages/' + slug + '?_embed';
			}
			window.axios.get(url)
				.then(res => {
					if (res.status !== 200) {
						// Show some error or 404 page
						this.$router.replace({name: 'error'});
					}
					if(!res.success  && res.data && res.data.data === 'no-access') {
						console.log('forbidden');
						this.$router.replace({name: 'forbidden'});
						return;
					}

					if (isNaN(slug)) {
						if ([...res.data].length === 0) {
						// Show some error or 404 page
							this.$router.replace({name: 'oops'});
						}
						this.page = [...res.data][0];
					} else {
						this.page = {...res.data};
					}

					this.ready = typeof (this.page.acf) !== 'undefined';
				}).catch(err => {
					// Show some error or 404 page
					this.$router.replace({name: 'error'});
				});
		},
	},
};
</script>
